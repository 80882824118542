<!--
 * @Author: lihuaruiz lihuarui@huawei.com
 * @Date: 2024-06-04 16:21:22
 * @LastEditors: lihuaruiz lihuarui@huawei.com
 * @LastEditTime: 2024-07-04 11:18:09
 * @FilePath: \vue2Admin\src\views\systemsetting\account\detail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
vue
<template>
  <div>
    <!-- <div class="back-button" @click="goBack">
      <i class="el-icon-back"></i><span>详情</span>
    </div> -->
    <div class="detail-content" style="padding: 0px 15px 25px 15px">
      <!-- <p style="margin: 0px; font-size: 20px; font-weight: 800">商户详情</p> -->
      <el-row>
        <el-col class="item"
          ><div class="term">姓名:</div>
          <div class="detail">{{ itemDetail.name }}</div></el-col
        >
        <el-col class="item"
          ><div class="term">电话:</div>
          <div class="detail">{{ itemDetail.phone }}</div></el-col
        >
        <el-col class="item"
          ><div class="term">身份证号码:</div>
          <div class="detail">
            {{ itemDetail.identityCard }}
          </div></el-col
        >
        <!-- <el-col class="item"
          ><div class="term">身份证号:</div>
          <div class="detail">{{ itemDetail.id }}</div></el-col
        > -->
        <el-col class="item"
          ><div class="term">是否认证:</div>
          <div class="detail">
            {{
              itemDetail.name && itemDetail.phone && itemDetail.idCard
                ? "是"
                : "否"
            }}
          </div></el-col
        >
        <el-col class="item"
          ><div class="term">注册时间:</div>
          <div class="detail">{{ itemDetail.registrationTime }}</div></el-col
        >
        <el-col class="item"
          ><div class="term">经营地:</div>
          <div class="detail">{{ itemDetail.city }}</div>
          <!-- <el-select
            v-model="value"
            placeholder="请选择"
            multiple="true"
            @blur="getDetialCities()"
          >
            <el-option
              v-for="item in cities"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <span style="float: left">{{ item.label }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.value
              }}</span>
            </el-option>
          </el-select> -->
        </el-col>
        <el-col class="item" style="width: 400px"
          ><div class="term" style="width: 105px">线索工单上限:</div>

          <el-tooltip
            class="item"
            effect="dark"
            content="输入为正数时是添加，为负数时是减少"
            placement="bottom"
            style="margin-top: 0px"
          >
            <el-input-number
              v-model="value"
              controls-position="right"
            ></el-input-number>
          </el-tooltip>
          <button
            type="sumbit"
            style="
              width: 50px;
              height: 30px;
              background-color: #409eff;
              border: 0px solid #000000;
              border-radius: 3px;
              margin-left: 10px;
            "
            @click="getTicketCap(detailId, value)"
          >
            确认
          </button>
          <!-- <el-divider></el-divider
        > -->
        </el-col>

        <!-- <el-col class="item"
          ><div class="term">线索下发时间:</div>
          <div class="detail">{{ itemDetail.id }}</div></el-col
        >
        <el-col class="item"
          ><div class="term">新车指导价/卖车暂定价:</div>
          <div class="detail">{{ itemDetail.id }}</div></el-col
        >
        <el-col class="item"
          ><div class="term">电话：</div>
          <div class="detail">11111111111</div> </el-col
        ><el-col class="item"
          ><div class="term">通话记录:</div>
          <div class="detail">11111111111</div></el-col
        ><el-col style="width: 350px; display: flex; margin-top: 25px"
          ><div class="term">跟进记录:</div>
          <el-input style="width: 230px"></el-input
        ></el-col>
        <el-col style="width: 450px; display: flex; margin-top: 25px"
          ><div class="term">工单处理:</div>
          <div style="margin-left: 10px">
            <el-button>下发工单</el-button><el-button>下次跟进</el-button
            ><el-button>暂时不卖</el-button>
          </div></el-col
        > -->
      </el-row>
      <!-- <div v-if="title" class="title">{{ title }}</div> -->
      <!-- <el-row :gutter="gutter">
        <el-col :key="key" :span="+col" v-for="(item, key) in itemDetail">
          <div class="term">{{ item.term }}</div>
          <div class="detail">
            {{ item.detail
            }}<image
              src="../../assets/dianhua.png"
              v-show="dataSource.phone"
            ></image>
          </div>
        </el-col>
      </el-row> -->
    </div>
    <div class="detail-content">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="1">线索购买记录</el-menu-item>
        <!-- <el-menu-item index="2">消息中心</el-menu-item> -->
      </el-menu>
      <div class="line"></div>
      <el-table
        :data="listData"
        stripe
        style="width: 100%; margin-top: 10px"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
      >
        <el-table-column label="序号" width="70" align="left">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          v-for="tab in tableTitle1"
          :key="tab.code"
          :label="tab.name"
          show-overflow-tooltip
          :width="tab.width"
        >
          <template slot-scope="scope">
            <span>{{ scope.row[tab.code] }} </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
// import DescriptionList from "../../../components/Description/index.vue";
import {
  getgetDetailList,
  getPurchaseHistory,
  updataUpperLimit,
} from "../../../api/common";
import router from "../../../router";

export default {
  data() {
    return {
      itemDetail: {},
      activeIndex: "1",
      detailId: this.$route.params.id
        ? this.$route.params.id
        : localStorage.getItem("id"),
      listData: [],
      cities: [
        {
          value: "Hangzhou",
          label: "杭州",
        },
        {
          value: "Huzhou",
          label: "湖州",
        },
        {
          value: "Shaoxing",
          label: "绍兴",
        },
        {
          value: "Ningbo",
          label: "宁波",
        },
        {
          value: "Jiaxing",
          label: "嘉兴",
        },
        {
          value: "Lishui",
          label: "丽水",
        },
        {
          value: "Taizhou",
          label: "台州",
        },
        {
          value: "Wenzhou",
          label: "温州",
        },
        {
          value: "Jinhua",
          label: "金华",
        },
        {
          value: "Quzhhou",
          label: "衢州",
        },
        {
          value: "Zhoushan",
          label: "舟山",
        },
      ],
      value: 0,
      tableTitle1: [
        { name: "商户ID", code: "id", width: "" },
        { name: "时间", code: "timeOfDay", width: "" },
        // { name: "城市", code: "city", width: "" },
        { name: "工单上限", code: "setMeal", width: "" },
        // { name: "已下发工单数", code: "issue", width: "120" },
        // { name: "申诉数", code: "appeal", width: "" },
        // { name: "申诉成功", code: "appealSucceed", width: "" },
        // { name: "已成交", code: "turnover", width: "" },
      ],
      // tableTitle2: [
      //   { name: "ID--2", code: "id", width: "" },
      //   { name: "所在城市", code: "city", width: "" },
      //   { name: "车型", code: "model", width: "" },
      //   { name: "上牌时间", code: "registrationTime", width: "" },
      //   { name: "手机号码", code: "phone", width: "" },
      //   { name: "线索下发时间", code: "issueTime", width: "" },
      //   { name: "首次联系时间", code: "lastFollowUpTime", width: "" },
      //   { name: "联系状态", code: "contactState", width: "" },
      // ],
    };
  },
  components: {
    // DescriptionList,
  },
  mounted() {
    console.log(localStorage.getItem("id"));
    this.getItemDetail(this.detailId);
  },
  methods: {
    // 关闭标签事件
    goBack() {
      // 判断路由是否能返回
      if (window.history.length <= 2) {
        this.goHome();
      } else {
        this.$router.go(-1);
      }
    },
    getItemDetail(cid) {
      //获取单行数据详情
      getgetDetailList(cid).then((res) => {
        //执行成功后代码处理
        this.itemDetail = res.data.commercial;
        this.itemDetail.phone =
          this.itemDetail.phone.slice(0, 3) +
          "****" +
          this.itemDetail.phone.slice(7, 11);
        this.itemDetail.identityCard =
          this.itemDetail.identityCard.slice(0, 3) +
          "************" +
          this.itemDetail.identityCard.slice(15, 18);
        this.itemDetail.registrationTime =
          this.itemDetail.registrationTime.slice(0, 10);
      });
      getPurchaseHistory(cid).then((res) => {
        this.listData = res.data.queryResult.list;
      });
    },
    handleSelect(key) {
      this.activeIndex = key;
    },
    getDetialCities() {},
    getTicketCap(id, value) {
      const amend = this.value > 0 ? "添加" : "减少";
      const num = this.value < 0 ? -this.value : value;
      console.log(id, num, amend);
      updataUpperLimit(id, num, amend).then((res) => {
        if (res.data.code == 10001) {
          router.push("/login");
          this.msgerror(res.data.message);
        } else {
          if (res.data.code == 10010) {
            this.msgsuccess(res.data.message);
          } else if (res.data.code == 10011) {
            this.msgsuccess(res.data.message);
          } else {
            this.msgerror("修改失败！");
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.back-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  color: #409eff;
}

.detail-content {
  padding: 15px;
  margin: 20px 15px;
  background-color: #fff;
  border: 1px solid #e2e2e4;
}
.back-button {
  margin-left: 20px;
  margin-top: 20px;
}
.item {
  display: flex;
  width: 240px;
  margin-top: 25px;
}
.term {
  margin-right: 10px;
}
.detail {
  margin-right: 10px;
}
</style>
